<template>
  <div>
    <InventoryTable
      :title="tLabel('Truck visits')"
      :headers="headers"
      :defaultFilters="defaultFilters"
      :defaultSort="defaultSort"
      :excludeButtons="{ unitNumber: true, sysTypes: true, tipoUnit: true, voyages: true }"
    >
      <template v-slot:filterButtons="{ filters }">
        <v-col cols="4">
          <v-text-field :label="tLabel('License plate')" v-model="filters['B.object_codeLike']" @input="(val) => (filters['B.object_codeLike'] = val ? val.toUpperCase() : null)" />
        </v-col>
        <v-col cols="2">
          <v-switch :label="tLabel('Mostra usciti')" v-model="filters['show_exited']" />
        </v-col>
      </template>
      <template v-slot:item-menu="{ item }">
        <v-list>
          <!-- {{ item }} -->
          <!-- :disabled="!['E', 'Y'].includes(item.status)" -->
          <v-list-item @click="goToEditTransit(item.id)">
            <v-list-item-title class="text-uppercase">{{ tLabel("Consegna/ritiro") }}</v-list-item-title>
            <v-list-item-action>
              <v-icon color="secondary" medium>mdi-swap-vertical</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-list-item @click="prepareGateOut(item)" :disabled="!['E', 'Y'].includes(item.status)">
            <v-list-item-title class="text-uppercase">{{ tLabel("Gate-out") }}</v-list-item-title>
            <v-list-item-action>
              <v-icon color="secondary" medium :disabled="!['E', 'Y'].includes(item.status)">mdi-logout-variant</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-divider class="blue lighten-3 mx-2" />
          <v-list-item @click="stampa(item, 'IN')" :disabled="item.dataIn === null">
            <v-list-item-title class="text-uppercase">{{ tLabel("Stampa IN") }}</v-list-item-title>
            <v-list-item-action>
              <v-icon color="secondary" medium :disabled="item.dataIn === null">mdi-printer-outline</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-list-item @click="stampa(item, 'OUT')" :disabled="item.dataOut === null">
            <v-list-item-title class="text-uppercase">{{ tLabel("Stampa OUT") }}</v-list-item-title>
            <v-list-item-action>
              <v-icon color="secondary" medium :disabled="item.dataOut === null">mdi-printer-outline</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </template>
    </InventoryTable>
    <v-dialog v-model="dialogGateOutVisible" fullscreen scrollable persistent>
        <v-card v-if="mission">
          <v-card-title class="text-h5 text-uppercase panelheader">
            {{ tLabel("Gate-out") }}
            <v-spacer />
            <v-icon large @click="dialogGateOutVisible=false">mdi-close</v-icon>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row align="center" class="blue lighten-5 mt-1">
                <v-col cols="4">
                  <LabeledContent :value="mission.truck.plate" :label="tLabel('License Plate')" />
                </v-col>
                <v-col cols="4">
                  <LabeledContent :value="mission.driver.name+' '+mission.driver.surname" :label="tLabel('Driver')" />
                </v-col>
              </v-row>
            </v-container>
            <DropoffPanel :truckVisitId="''+mission.truck.id" />
            <PickupPanel :truckVisitId="''+mission.truck.id"  />
            <v-container fluid>
              <v-row align="center">
                <v-col cols="12">
                  <v-textarea :label="tLabel('Remarks')" v-model="mission.remarks" counter maxlength="255" />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn medium outlined class="pa-2 ma-2" @click="dialogGateOutVisible=false"> <v-icon class="mr-2">mdi-cancel</v-icon>{{ tLabel("Cancel") }} </v-btn>
            <v-btn color="primary" class="pa-2 ma-2" @click="doGateOut(false)" medium> <v-icon class="mr-2">mdi-logout-variant</v-icon>{{ tLabel("Gate-out") }} </v-btn>
            <v-btn color="primary" class="pa-2 ma-2" @click="doGateOut(true)" medium> <v-icon class="mr-2">mdi-printer-outline</v-icon>{{ tLabel("Gate-out + Stampa") }} </v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>
<script>
import InventoryTable from "@/components/yard/InventoryTable";
import DropoffPanel from "@/components/yard/DropoffPanel.vue";
import PickupPanel from "@/components/yard/PickupPanel.vue";
import LabeledContent from "@/components/yard/LabeledContent.vue";
import ToastMixin from "@/mixins/ToastMixin";

export default {
  name: "TruckInventoryVisits",
  data() {
    return {
      item: null,
      defaultFilters: { sysTypeIn: ["RRO"], show_exited: false },
      dialogGateOutVisible: false,
      mission: null, 
      //obsoleti?
      defaultSort: { field: "data_in", desc: false },
      unitTypes: [],
      roroTypes: [],
      sysTypes: null,
      headers: [
        { text: `${this.tLabel("License plate")}`, value: "objectCode" },
        // { text: `${this.tLabel("Driver")}`, value: "hinDriver" },
        // { text: `${this.tLabel("Order")}`, value: "hinOrderCode" },
        { text: `${this.tLabel("Date In")}`, value: "dataIn", formatValue: "formatDateTimeFromDate" },
        { text: `${this.tLabel("Date Out")}`, value: "dataOut", formatValue: "formatDateTimeFromDate" },
      ],
    };
  },
  mixins: [ToastMixin],
  async mounted() {
    this.loadCombo();
  },
  async created() {
    this.$eventBus.$on("gateOutDone", (gateOutDone) => {
      console.log("gateOutDone", gateOutDone);
      this.dialogGateOutVisible = false;
      if (gateOutDone === true) {
        this.$emit("refreshTable");
      }
    });
  },
  methods: {
    cargoMissionFiltered(missionType) {
      return this.mission.cargoes.filter(c=>c.missionType==missionType);
    },
    goToEditTransit(visitId) {
      console.log("goToEditTransit visitId", visitId);
      this.$router.push({
          path: "/yard/inventory/editTransits/" + visitId
        });
    },
    async prepareGateOut(item) {
      console.log("prepareGateOut item", item);
      const isRoro = item.sysType == "RRO";
      if(!isRoro) {
        alert("Not a truck!");
        return;
      }
      const idTruck = item.id;
      const truckLicensePlate = item.objectCode;
      const mr= await this.$api.yardData.getMissionByPlate(truckLicensePlate);
      if (mr == null){
        alert("Mission not found!");
        return;
      }
      this.mission=mr.mission;
      this.dialogGateOutVisible = true;
    },
    async doGateOut(print) {
      try {
        const gateResponse = await this.$api.yardData.gateOutMission(this.mission);
        console.log("gateResponse:", gateResponse);
        const handlingId =gateResponse.code;
        if (print) this.stampaInterchange(null, handlingId, "OUT");
        this.$eventBus.$emit("gateOutDone", true);
      } catch (e) {
        const msg = e.detailMessage ? e.detailMessage : e.message;
        this.showError("Errore gate out: " + msg);
        console.log(e);
      }
    },
    async stampa(item, direction) {
      var handlingId = null;
      if (direction === "IN") {
        handlingId = item.handlingsIn !== null ? item.handlingsIn[0].id : null;
      } else {
        handlingId = item.handlingsOut !== null ? item.handlingsOut[0].id : null;
      }

      if (handlingId !== null) {
        this.stampaInterchange(item.id, handlingId, direction);
      }
    },
    async stampaInterchange(visitId, handlingId, direction) { //Duplicato da GateOut. TODO refactor!
      try {
        const _callConfig = {
          responseType: "blob",
          headers: {
            "Content-Type": "application/pdf",
            Accept: "*/*",
            Pragma: "no-cache",
          },
        };
        const url =
          direction === "IN" ? (
              handlingId != null ? `${this.$apiConfiguration.BASE_PATH_YARD}reports/interchangeInByHandlingId/` + handlingId : `${this.$apiConfiguration.BASE_PATH_YARD}reports/interchangeInByVisitId/` + visitId
            ) : (
              handlingId != null ? `${this.$apiConfiguration.BASE_PATH_YARD}reports/interchangeOutByHandlingId/` + handlingId : `${this.$apiConfiguration.BASE_PATH_YARD}reports/interchangeOutByVisitId/` + visitId
            );
        console.log("URL Stampa; ", url);
        const response = await this.$api.get(url, _callConfig);
        const blob = new Blob([response], { type: "application/pdf;charset=ANSI" });
        const fileURL = window.URL.createObjectURL(blob);
        const fileLink = document.createElement("a");
        fileLink.setAttribute("href", fileURL);
        fileLink.setAttribute("download", "Interchange3.pdf");
        document.body.appendChild(fileLink);
        fileLink.click();
      } catch (e) {
        console.error("Errore Interchange", e);
      }
    },
    async loadCombo() {
      this.sysTypes = await this.$api.get(this.$apiConfiguration.BASE_PATH_YARD + "lookups/sysTypes");
      this.unitTypes = await this.$api.get(this.$apiConfiguration.BASE_PATH_YARD + "unit-types/comboList");
      let goodsType = await this.$api.get(this.$apiConfiguration.BASE_PATH_YARD + "goods-types/comboList");
      this.unitTypes.push.apply(this.unitTypes, goodsType);
      this.roroType = await this.$api.get(this.$apiConfiguration.BASE_PATH_YARD + "rorotypes/comboList");
      this.unitTypes.push.apply(this.unitTypes, this.roroType);
    },
  },
  components: { InventoryTable, DropoffPanel, PickupPanel, LabeledContent },
};
</script>
